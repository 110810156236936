import { useLayoutEffect } from "react";
import { useNavigate } from "react-router-dom";
import { setupInterceptors } from "./http";

export function HttpInterceptors() {
  const navigate = useNavigate();

  useLayoutEffect(() => {
    //On_Mount
    setupInterceptors(navigate);

  }, []);

  return null;
}